/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;





.h1 {
  font-size: 3rem;
  font-weight: 800;
  line-height: 1;

}

.h2 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  letter-spacing: -0.025em;
  font-weight: 700;

}



.h3 {
  font-size: 1.875rem;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: -0.025em;
}

.h4 {
  font-size: 1.5rem;
  line-height: 1.25;
  letter-spacing: -0.025em;
  font-weight: 700;
}

.btn .btn-sm {
  position: relative;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 2rem/* 32px */;
  padding-right: 2rem/* 32px */;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 9999px;
  outline: 2px solid transparent;
  outline-offset: 2px;
}







.box {
  // width: 100%;
  // height: 100%;
  display:inline-block;
  transition:  1s;
}
.to-center{
  background:
    linear-gradient(transparent,transparent) no-repeat,
    transparent;
  background-size: 100% 100%;
  background-position:center;
}
.to-center:hover, .to-center:active {
  background-size: 0% 100%; /* Or 100% 0% */
  background-color:rgba(152, 190, 226, 0.4);

  opacity: 20;
}

.diagonal-right{
  background-image:linear-gradient(to bottom right,rgba(134, 161, 198, 0.785) 49.5%,transparent 50%);
  background-size: 200% 200%;
  background-position:bottom right;
}

.diagonal-right:hover, .diagnoal-right:active {
  background-position:top left;
}

.diagonal-right2{
  background-image:linear-gradient(to bottom right,rgba(16, 164, 232, 0) 49.5%,transparent 50%);
  background-size: 200% 200%;
  background-position:bottom right;
}

.diagonal-right2:hover, .diagnoal-right2:active {
  background-position:top left;
}

.bhc1 {
  font-family:'Trebuchet MS';
  // text-align: center;
  font-size: 6vmax;
  z-index: -10;
  color:transparent;
  -webkit-text-stroke: 1.5px;
  -webkit-text-stroke-color: #86A1C6;

}

.bhc2 {
  font-family:'Trebuchet MS';
  // text-align: center;
  font-size: 6vmax;
  z-index: -10;
  color:transparent;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: #1b4d61;

}

.bhc3 {
  font-family:'Trebuchet MS';
  // text-align: center;
  font-size: 6vmax;
  z-index: -10;
  color:transparent;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: #ffffff;
}

.strokeme {
  text-shadow: -1px -1px 0 #60A5FA, 1px -1px 0 #60A5FA, -1px 1px 0 #60A5FA, 1px 1px 0 #60A5FA;
  // font-size:7vw;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

